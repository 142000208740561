<template>
    <div class="content-window">

        <div class="disc-fragments-grid">
            <div class="disc-fragments-header disc-fragments-item1">
                <div v-html="getText('Fragments of what could have been')"/> 
            </div>

            <div class="disc-fragments-cover disc-fragments-item2">
                <img src="fragments cover.svg">
            </div>

            <div class="disc-fragments-text disc-fragments-item3" v-on:click="clickButton('papers')">
                <div v-html="getText('1. Have you read the papers lately?')"/> 
            </div>

            <div class="disc-fragments-text disc-fragments-item4" v-on:click="clickButton('stranger')">
                <div v-html="getText('2. A stranger I once knew')"/>
            </div>

            <div class="disc-fragments-text disc-fragments-item5" v-on:click="clickButton('dirtyFace')">
                <div v-html="getText('3. Dirty face')"/>
            </div>

            <div class="disc-fragments-text disc-fragments-item6" v-on:click="clickButton('danceMonkey')">
                <div v-html="getText('4. Dance monkey dance')"/>
            </div>

            <div class="disc-fragments-text disc-fragments-item7" v-on:click="clickButton('gone')">
                <div v-html="getText('5. Until you\'re gone')"/>
            </div>

            <div class="disc-fragments-text disc-fragments-item8" v-on:click="clickButton('factor')">
                <div v-html="getText('6. The only common factor')"/>
            </div>

            <div class="disc-fragments-text disc-fragments-item9" v-on:click="clickButton('writersBlock')">
                <div v-html="getText('7. Writer\'s block')"/> 
            </div>
        </div>

    </div>

</template>

<script>
    import helpers from '../helpers/helpers.js';

    export default {
        name: 'DiscographyTab',
        data() {
            return {
                buttonClicked: null
            }
        },
        methods: {
            getText(text) {
                return helpers.textToCSV(text);
            },
            clickButton(buttonClicked) {
                this.clickedButton = buttonClicked;
                this.$emit("clicked-discography-button", this.clickedButton);
            },
        }
    }
</script>

