<template>
    <div class="content-window bio-window">
        <div class="bio-text">
            <div v-html="getText3()"/> 
        </div>

        <div class="bio-copy" v-on:click="copyText()">
            <div v-html="getText('Copy ')" ></div>
            <img src="copy.svg">
        </div>
    </div>
</template>

<script>
    import helpers from '../helpers/helpers.js';
    import bioText from 'raw-loader!../text/./bio.txt';
    
    export default {
        name: 'BioTab',
        data() {
            return {
                innerWidth: window.innerWidth
            }
        },
        mounted() {
            window.addEventListener("resize", this.myEventHandler);
        },
        unmounted() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        methods: {
            myEventHandler() {
                this.innerWidth = window.innerWidth;
            },
            getBioText() {
                let lines = bioText.split("\n");
                let retunString = "<br>";
                for (let index = 0; index < lines.length; index++) {
                    const element = helpers.textToCSV(lines[index]);
                    retunString += element + "<br>";
                }
                return retunString;
            },
            async copyText() {
                try {
                    await navigator.clipboard.writeText(bioText);
                } catch($e) {
                    console.log("Failed to copy text", $e)
                }
            },
            getText(text) {
                return helpers.textToCSV(text);
            },
            getText2(text, maxLength) {
                let words = text.split(" ");
                let asdf = "<br>";
                let totalLength = 0;
                for (let index = 0; index < words.length; index++) {
                    totalLength += words[index].length;

                    const element = helpers.textToCSV(words[index] + " ");
                    //const element =  " "  + index + " "  + helpers.textToCSV(words[index] + " ") + " " + totalLength + " " + (totalLength >= maxLength);
                    if(totalLength > maxLength) {
                        asdf += "<br>" + element;
                        totalLength = words[index].length;
                    } else {
                        asdf += element;
                        totalLength += 1; //plus 1 for the space
                    }
                }
                return asdf;
            },
            getMaxLength() {
                if (this.innerWidth < 400) {
                    return 28;
                } else if (this.innerWidth >= 400 && this.innerWidth < 600) {
                    return 32;
                } else if (this.innerWidth >= 600 && this.innerWidth < 800) {
                    return 39;
                } else if (this.innerWidth >= 800 && this.innerWidth < 1000) {
                    return 46;
                } else if (this.innerWidth >= 1000 && this.innerWidth < 1300) {
                    return 65;
                }  else {
                    return 65;
                }
            },
            getText3() {
                let lines = bioText.split("\n");
                let retunString = "";
                let maxLength = this.getMaxLength();
                for (let index = 0; index < lines.length; index++) {
                    retunString += this.getText2(lines[index], maxLength);
                }
                return retunString;
            }
        }
    }
</script>
