'use strict'

function charToCSV(char) {
    if (char.match(/^[0-9a-zA-Zåäöüß]+$/)) {
        let lowerChar = char.toLowerCase();
        return "<img src=\"Characters/" + lowerChar + ".svg\">";
    } else if(char.includes(" ")) {
        return "<img src=\"Characters/empty.svg\">";
    } else if(char.includes(".")) {
        return "<img src=\"Characters/dot.svg\">";
    } else if(char.includes(",")) {
        return "<img src=\"Characters/comma.svg\">";
    } else if(char.includes("!")) {
        return "<img src=\"Characters/exclamation mark.svg\">";
    } else if(char.includes("?")) {
        return "<img src=\"Characters/question mark.svg\">";
    } else if(char.includes(":")) {
        return "<img src=\"Characters/colon.svg\">";
    } else if(char.includes("-")) {
        return "<img src=\"Characters/dash.svg\">";
    } else if(char.includes("'")) {
        return "<img src=\"Characters/apostrophe.svg\">";
    } else if(char.includes("(")) {
        return "<img src=\"Characters/bracketLeft.svg\">";
    } else if(char.includes(")")) {
        return "<img src=\"Characters/bracketRight.svg\">";
    } else if(char.includes("@")) {
        return "<img src=\"Characters/@.svg\">";
    }
    return char;
}

function textToCSV(text) {
    let outer = "";
    for(let i = 0; i < text.length; i++) {
        outer += charToCSV(text[i]);
    }
    return outer;
}


export default {
    charToCSV: charToCSV,
    textToCSV: textToCSV
}

